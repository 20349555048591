import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - HD Group"
  const activePageText = "Case Study HD Group"

  // Main Content Area
  const type =
    "1 Admin Website + 1 Customer Facing Website + 1 Business facing Website + 1 iOS & Android App + 1 Backend Database + 1 Node JS Server";

const title = "A full evolving staffing application suite."
const body1 = "The Challenge - HD Group's challenge was to digitise and syndicate data in the temporary staffing industry, between workers, agencies, and businesses. Application MVP was built first with a simpler React + Firebase stack at 10% of the final version budget before we migrated to a more customizable and scalable stack with Mongo, React, React Native and Node."
const body2 = "The Solution - We built a full suite product with complex time tracking, shift tracking, automatic training and data view permissions for 3rd party sources. Sensitive data had to be collected and securely stored. Workers themselves and see their shifts, and pick up new temporary shifts on the app."
const body3 = "After 1.5 year development, the agency was able to cut half their staff while maintaining the same number of clients. This increased their business valuation almost 2x due to far more operating profits."
const results = "The Result - HD Group's product development and integration reduced significant company inefficiencies, and increased productivity for themselves, their workers, and their businesses."


  // Side bar content area
  const clientName = "HD Group"
  const clientLink = "https://hdgroup.ca"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "Etobicoke, ON, Canada"
  const technologies = "Front-end: React Native, React   Back-end: Node JS, Mongo Atlas"
  const completionDate = "2021 May 30"
  
  const products = [{ hrefName: "HD Group - iOS", href: "https://apps.apple.com/ca/app/hd-group-workers-app/id1561042723" },
                    { hrefName: "HD Group - Android", href: "https://play.google.com/store/apps/details?id=ca.hdgroup.worker&hl=en_CA&gl=US" },
                    { hrefName: "HD Group - Admin" },
                  ]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
